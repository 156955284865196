import type { I18nextProviderProps } from 'react-i18next';
import { setLocale } from 'yup';

export const setYupLocale = ({ t }: I18nextProviderProps['i18n']) => {
    setLocale({
        mixed: {
            required: t('mappings.yup_validation.required'),
            notType: ({ type }: { type: string }) => t('mappings.yup_validation.incorrect_type', { type }),
        },
        number: {
            min: ({ min }) => t('mappings.yup_validation.min_number', { count: min }),
            max: ({ max }) => t('mappings.yup_validation.max_number', { count: max }),
        },
        string: {
            email: t('mappings.yup_validation.email'),
            url: t('mappings.yup_validation.url'),
        },
        array: {
            min: ({ min }) => t('mappings.yup_validation.min_array_length', { count: min }),
            max: ({ max }) => t('mappings.yup_validation.max_array_length', { count: max }),
        },
        date: {
            min: ({ min }) => t('mappings.yup_validation.min_date', { date: min }),
            max: ({ max }) => t('mappings.yup_validation.max_date', { date: max }),
        },
    });
};
