import type { components } from '../../types/ApiError';

export type ApiErrorCode = components['schemas'][keyof components['schemas']]['errorCode'];

enum ApiErrorCause {
    BadRequest = 'Bad Request',
    Unauthorized = 'Unauthorized',
    Forbidden = 'Forbidden',
    NotFound = 'NotFound',
    Unknown = 'Unknown',
}

const responseStatusToApiErrorCause: Record<Response['status'], ApiErrorCause> = {
    400: ApiErrorCause.BadRequest,
    401: ApiErrorCause.Unauthorized,
    403: ApiErrorCause.Forbidden,
    404: ApiErrorCause.NotFound,
};

type ApiErrorMetaDto = components['schemas'][keyof components['schemas']];

type ApiValidationErrorMetaDto<T> = Omit<components['schemas']['AirMethodArgumentNotValidException'], 'payload'> & {
    payload: Record<keyof T, components['schemas']['AirMethodArgumentNotValidException']['payload'][string]>;
};

/**
 * Client-side general API error.
 */
export class ApiError<T = unknown> extends Error {
    public override readonly cause: ApiErrorCause;

    public readonly meta?: T;

    public static is(error: unknown): error is ApiError {
        return error instanceof ApiError;
    }

    /**
     * Returns true if the passed argument is ApiErrorMetaDto.
     *
     * Please bear in mind that this method checks if the argument is meta,
     * so you mustn't provide ApiError here because it will throw an error.
     * To use this method in a component:
     * ApiError.is(error) && ApiError.isApiErrorMetaDto(error.meta)
     */
    public static isApiErrorMetaDto(errorMeta: unknown): errorMeta is ApiErrorMetaDto {
        if (ApiError.is(errorMeta)) {
            throw new Error('ApiError instance is passed to isApiErrorMetaDto. Please, provide a meta object.');
        }

        if (!errorMeta || typeof errorMeta !== 'object') {
            return false;
        }

        return 'errorCode' in errorMeta;
    }

    /**
     * Returns true if the passed argument is ApiValidationErrorMetaDto.
     * TPayloadKeys adds types for a meta.payload property.
     *
     * Please bear in mind that this method checks if the argument is meta,
     * so you mustn't provide ApiError here because it will throw an error.
     * To use this method in a component:
     * ApiError.is(error) && ApiError.isApiValidationErrorMetaDto(error.meta)
     */
    public static isApiValidationErrorMetaDto<TPayloadKeys = unknown>(
        errorMeta: unknown,
    ): errorMeta is ApiValidationErrorMetaDto<TPayloadKeys> {
        // ApiValidationErrorMetaDto is a subset of ApiErrorMetaDto,
        // we use one class since it's easier to maintain.
        if (!this.isApiErrorMetaDto(errorMeta)) {
            return false;
        }

        return errorMeta.errorCode === 'AirMethodArgumentNotValidException';
    }

    public static Causes = ApiErrorCause;

    public constructor({ message = 'ApiError', response, meta }: { message?: string; response: Response; meta?: T }) {
        super(message);
        this.cause = responseStatusToApiErrorCause[response.status] ?? ApiErrorCause.Unknown;
        this.meta = meta;
    }
}
