import ar_translation from './ar/translation.json';
import cs_translation from './cs/translation.json';
import de_translation from './de/translation.json';
import en_translation from './en/translation.json';
import es_translation from './es/translation.json';
import fr_translation from './fr/translation.json';
import he_translation from './he/translation.json';
import it_translation from './it/translation.json';
import ja_translation from './ja/translation.json';
import ko_translation from './ko/translation.json';
import pl_translation from './pl/translation.json';
import pt_translation from './pt/translation.json';
import ru_translation from './ru/translation.json';
import tr_translation from './tr/translation.json';
import vi_translation from './vi/translation.json';

/**
 * This file adds type definitions for JSON translations.
 * @see https://www.i18next.com/overview/typescript
 */
export const resourcesList = {
    ar: { translation: ar_translation },
    cs: { translation: cs_translation },
    de: { translation: de_translation },
    en: { translation: en_translation },
    es: { translation: es_translation },
    fr: { translation: fr_translation },
    he: { translation: he_translation },
    it: { translation: it_translation },
    ja: { translation: ja_translation },
    ko: { translation: ko_translation },
    pl: { translation: pl_translation },
    pt: { translation: pt_translation },
    ru: { translation: ru_translation },
    tr: { translation: tr_translation },
    vi: { translation: vi_translation },
} as const;
