import createClient from 'openapi-fetch';
import type { MediaType } from 'openapi-typescript-helpers';

import { createAuthMiddleware } from './createAuthMiddleware';
import { wrapClientMethod } from './wrapApiMethod';

import { FRONTEND_ENV } from '#shared/const/FRONTEND_ENV';

type BackendApiClientOptions = {
    unprotectedRotes?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type -- types are same as the library ones
export const backendApiClient = <Paths extends {}, Media extends MediaType = MediaType>(
    config?: BackendApiClientOptions,
) => {
    const client = createClient<Paths, Media>({
        baseUrl: FRONTEND_ENV.BACKEND_API_URL,
    });

    const authMiddleware = createAuthMiddleware({
        unprotectedRotes: config?.unprotectedRotes,
    });

    client.use(authMiddleware);

    return {
        /** Call a GET endpoint */
        GET: wrapClientMethod<Paths, 'get', Media>(client.GET),
        /** Call a PUT endpoint */
        PUT: wrapClientMethod<Paths, 'put', Media>(client.PUT),
        /** Call a POST endpoint */
        POST: wrapClientMethod<Paths, 'post', Media>(client.POST),
        /** Call a DELETE endpoint */
        DELETE: wrapClientMethod<Paths, 'delete', Media>(client.DELETE),
        /** Call a OPTIONS endpoint */
        OPTIONS: wrapClientMethod<Paths, 'options', Media>(client.OPTIONS),
        /** Call a HEAD endpoint */
        HEAD: wrapClientMethod<Paths, 'head', Media>(client.HEAD),
        /** Call a PATCH endpoint */
        PATCH: wrapClientMethod<Paths, 'patch', Media>(client.PATCH),
        /** Call a TRACE endpoint */
        TRACE: wrapClientMethod<Paths, 'trace', Media>(client.TRACE),
    };
};
