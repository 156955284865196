import { Signal } from '@space/common/src/utils/Signal/Signal';

import { FRONTEND_ENV } from '../../const/FRONTEND_ENV';
import type { KeycloakState } from '../KeycloakService/KeycloakService';
import { KeycloakService } from '../KeycloakService/KeycloakService';

type AuthMethods = {
    readonly login: KeycloakService['login'];
    readonly logout: KeycloakService['logout'];
    readonly updateToken: KeycloakService['updateToken'];
};

export type AuthState = AuthMethods & KeycloakState;

const keycloakService = new KeycloakService();

if (FRONTEND_ENV.DEPLOYMENT_ENV !== 'test') {
    void keycloakService.init();
}

const { stateSignal, login, logout, updateToken } = keycloakService;

export const authState = new Signal<AuthState>({
    ...stateSignal.getValue(),
    login,
    logout,
    updateToken,
});

stateSignal.subscribe((authData) => {
    authState.next({
        ...authData,
        login,
        logout,
        updateToken,
    });
});
