export const ORGANIZATIONS_SERVICE_API_PREFIX = '/api/organizations/v1';
export const ORGANIZATIONS_SERVICE_API_PREFIX_V2 = '/api/organizations/v2';
export const ORGANIZATIONS_SERVICE_API_PREFIX_V3 = '/api/organizations/v3';
export const ORGANIZATIONS_PUBLIC_SERVICE_API_PREFIX = `${ORGANIZATIONS_SERVICE_API_PREFIX_V2}/public`;
export const ADVERTISERS_SERVICE_API_PREFIX = '/api/advertisers/v1';
export const ADVERTISERS_SERVICE_API_PREFIX_V2 = '/api/advertisers/v2';
export const PUBLISHER_SERVICE_API_PREFIX = '/api/publishers/v1';
export const SSPS_SERVICE_API_PREFIX = '/api/ssps/v1';
export const USERS_SERVICE_API_PREFIX = '/api/users/v1';
export const USERS_SERVICE_API_PREFIX_V2 = '/api/users/v2';
export const NOTIFICATION_SERVICE_API_PREFIX = '/api/notifications/v2';
export const ACCESS_SERVICE_API_PREFIX = '/api/access/v1';
export const AUDIT_SERVICE_API_PREFIX = '/api/audit/v2';
export const INTERNAL_BILLING_API_PREFIX = '/api-internal/billing/v1';
