import type {
    CheckUserAccessRequest,
    CheckUserAccessResponse,
    CreateUserAccess,
    UserAccess,
    UserAccessesInformation,
} from '@space/common/src/entities/UserAccess';
import type { UserData } from '@space/common/src/entities/UserData';

import { ACCESS_SERVICE_API_PREFIX } from '#shared/const/apiPrefixes';
import { backendApiRequest } from '#shared/utils/backendApiRequest/backendApiRequest';
import { accessClient } from '#shared/api/accessClient';

export function getUserAccesses(userId: UserData['userId']): Promise<UserAccessesInformation> {
    return backendApiRequest(`${ACCESS_SERVICE_API_PREFIX}/user/${userId}`);
}

export function getMyData() {
    return accessClient.GET('/api/access/v1/user/me');
}

export function checkUserAccess(data: CheckUserAccessRequest[]): Promise<CheckUserAccessResponse[]> {
    return backendApiRequest(`${ACCESS_SERVICE_API_PREFIX}/entity/access/check`, {
        method: 'POST',
        body: data,
    });
}

export function addUserAccesses(data: CreateUserAccess[]): Promise<UserAccessesInformation[]> {
    return backendApiRequest(`${ACCESS_SERVICE_API_PREFIX}/entity/accesses`, {
        method: 'POST',
        body: data,
    });
}

export function updateUserAccess(data: UserAccess): Promise<UserAccessesInformation[]> {
    return backendApiRequest(`${ACCESS_SERVICE_API_PREFIX}/entity/access`, {
        method: 'PUT',
        body: data,
    });
}

export function deleteUserAccess(data: UserAccess): Promise<null> {
    return backendApiRequest(`${ACCESS_SERVICE_API_PREFIX}/entity/access`, {
        method: 'DELETE',
        body: data,
    });
}
