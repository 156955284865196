export function deploymentEnvToSentryEnv(deploymentEnv: 'prod' | 'dev' | 'local' | 'test'): string {
    switch (deploymentEnv) {
        case 'prod':
            return 'production';
        case 'dev':
            return 'development';
        case 'local':
            return 'local';
        case 'test':
            return 'test';
        default:
            return deploymentEnv satisfies never;
    }
}
