import type { Middleware } from 'openapi-fetch';

import { authState } from '#shared/utils/authState/authState';

type AuthMiddlewareConfig = {
    /** pathnames without auth */
    unprotectedRotes?: string[];
};

export const createAuthMiddleware = ({ unprotectedRotes }: AuthMiddlewareConfig = {}): Middleware => {
    const unprotected = unprotectedRotes ?? [];

    return {
        onRequest({ request }) {
            if (unprotected.some((pathname) => request.url.startsWith(pathname))) {
                return undefined;
            }

            const currentAuthState = authState.getValue();

            if (currentAuthState.status === 'authenticated') {
                request.headers.set('Authorization', `Bearer ${currentAuthState.accessToken}`);
            }

            return request;
        },
    };
};
