import { ApiError } from '@space/common/src/entities/ApiError/ApiError';

import { FRONTEND_ENV } from '../../const/FRONTEND_ENV';

import type { RequestOptions } from './types/RequestOptions';
import type { RequestParameters } from './types/RequestParameters';
import { SESSION_TRACE_HEADER } from './constants/SESSION_TRACE_HEADER';

export class RequestError extends Error {
    public constructor(options: ErrorOptions) {
        super(undefined, options);
    }
}

export async function request<T>({ url, requestParameters, accessToken }: RequestOptions): Promise<T> {
    const { method, body, queryParams, responseType } = requestParameters;

    const requestUrl = buildUrl(url, queryParams);

    return fetch(requestUrl, {
        headers: buildHeaders({ accessToken, contentType: deriveContentTypeFromBody(body) }),
        method: method ?? 'GET',
        body: serializeBody(body),
    })
        .catch((e: unknown) => {
            if (e instanceof Error && e.name === 'TypeError') {
                throw new RequestError({ cause: e });
            }

            throw e;
        })
        .then(async (response) => {
            if (!response.ok) {
                let error: unknown;

                try {
                    error = await response.json();
                } catch (e) {
                    error = e;
                }

                throw new ApiError({ response, meta: error });
            }

            // This is done to support legacy code that uses request without specifying responseType.
            if (responseType === undefined) {
                const contentType = response.headers.get('Content-Type');

                // This logic ensures that we only attempt to parse a response as JSON if a content type is JSON.
                // For instance, the API can respond without a body and without the content type.
                if (contentType?.includes('application/json')) {
                    return response.json() as Promise<T>;
                }

                return response.text() as Promise<T>;
            }

            return response[responseType]() as Promise<T>;
        });
}

function buildUrl(originalUrl: string, queryParams: RequestParameters['queryParams']): string {
    const url = new URL(originalUrl);

    if (queryParams) {
        Object.entries(queryParams).forEach(([name, value]) => {
            if (value == null) return;

            if (value instanceof Array) {
                if (url.searchParams.has(name)) {
                    url.searchParams.delete(name);
                }

                value.forEach((item) => {
                    if (item == null) return;

                    url.searchParams.append(name, item.toString());
                });
            } else {
                url.searchParams.set(name, value.toString());
            }
        });
    }

    return url.toString();
}

function buildHeaders({
    accessToken,
    contentType,
}: {
    accessToken?: string;
    contentType?: string;
}): Record<string, string> {
    const headers: RequestInit['headers'] = {};

    if (contentType) {
        headers['Content-Type'] = contentType;
    }

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    /**
     * Helps the backend to combine multiple requests from a user into a "scenario".
     * Since user sessions start on the BFF, we generate this id there and pass it via FRONTEND_ENV.
     * @see https://st.yandex-team.ru/AIRSPACE-937
     */
    const userSessionId = FRONTEND_ENV.USER_SESSION_ID;

    if (userSessionId) {
        headers[SESSION_TRACE_HEADER] = userSessionId;
    }

    return headers;
}

function deriveContentTypeFromBody(body?: unknown): string | undefined {
    if (body == null) {
        return undefined;
    }

    if (typeof body === 'string') {
        return 'text/plain';
    }

    if (body instanceof FormData) {
        // content-type for form-data also contains boundary which is generated by browser
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#uploading_a_file
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST#example
        return undefined;
    }

    return 'application/json';
}

function serializeBody(body?: unknown): FormData | string | undefined {
    if (body == null) {
        return undefined;
    }

    if (typeof body === 'string') {
        return body;
    }

    if (body instanceof FormData) {
        return body;
    }

    return JSON.stringify(body);
}

export type Request = <T = unknown>(url: string, requestParameters?: RequestParameters) => Promise<T>;
