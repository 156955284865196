import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { deploymentEnvToSentryEnv } from '@space/common/src/utils/deploymentEnvToSentryEnv';

import { FRONTEND_ENV } from '#shared/const/FRONTEND_ENV';
import type { AuthState } from '#shared/utils/authState/authState';
import { authState } from '#shared/utils/authState/authState';
import { getMyData } from '#entities/access/api/api';

function updateUser(state: AuthState) {
    if (state.status === 'authenticated') {
        void getMyData().then((data) => {
            Sentry.setUser({
                id: data.userId,
                email: data.email,
            });
        });
    } else {
        Sentry.setUser(null);
    }
}

export function initSentry() {
    const { SENTRY_DSN, DEPLOYMENT_ENV } = FRONTEND_ENV;

    // do NOT enable sentry in a local environment
    if (!SENTRY_DSN) {
        return null;
    }

    Sentry.init({
        dsn: SENTRY_DSN,
        environment: deploymentEnvToSentryEnv(DEPLOYMENT_ENV),
        sendDefaultPii: true,
        beforeSendSpan(span) {
            // filter out spans about Yandex.Metrica to reduce noise
            if (span.op === 'http.client' && span.description?.includes('mc.yandex')) {
                return null;
            }

            return span;
        },
        beforeBreadcrumb(breadcrumb) {
            // filter out breadcrumbs about Yandex.Metrica to reduce noise
            if (
                breadcrumb.category === 'fetch' &&
                typeof breadcrumb.data?.url === 'string' &&
                breadcrumb.data.url.includes('mc.yandex')
            ) {
                return null;
            }

            return breadcrumb;
        },
        integrations: [
            Sentry.httpClientIntegration({
                failedRequestTargets: [FRONTEND_ENV.BACKEND_API_URL],
            }),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [FRONTEND_ENV.APP_URL, FRONTEND_ENV.BACKEND_API_URL],
        // Session Replay
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });

    updateUser(authState.getValue());
    authState.subscribe(updateUser);

    return Sentry;
}
