import 'vite/modulepreload-polyfill';
import { GLOBAL_DOCUMENT } from '@space/common/src/const/GLOBAL_DOCUMENT';
import { setYupLocale } from '@space/common/src/utils/setYupLocale/setYupLocale';
import { initI18n } from '@space/common/src/utils/initI18n/initI18n';
import { lazy, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { setDefaultOptions } from 'date-fns';

import { initSentry } from './initSentry';

/**
 * Initiates the application by configuring the required dependencies before loading Root.
 */
function initApp() {
    initSentry();

    // i18n must be initialized before using any translations.
    const i18n = initI18n();

    setYupLocale(i18n);
    setDefaultOptions({ weekStartsOn: 1 });

    const Root = lazy(() =>
        import('./components/Root/Root').then((m) => {
            return { default: m.Root };
        }),
    );

    const rootEl = GLOBAL_DOCUMENT.createElement('div');

    GLOBAL_DOCUMENT.body.appendChild(rootEl);

    const root = createRoot(rootEl);

    root.render(
        <StrictMode>
            <Root i18n={i18n} />
        </StrictMode>,
    );
}

initApp();
