import { ApiError } from '@space/common/src/entities/ApiError/ApiError';
import type { ClientMethod, FetchResponse, MaybeOptionalInit } from 'openapi-fetch';
import type { HasRequiredKeys, HttpMethod, MediaType, PathsWithMethod } from 'openapi-typescript-helpers';

import { authState } from '#shared/utils/authState/authState';
import { RequestError } from '#shared/utils/request/request';

function handleResult<T, O, M extends MediaType>(result: FetchResponse<T, O, M>) {
    if ('error' in result) {
        throw new ApiError({ response: result.response, meta: result.error });
    } else {
        return result.data;
    }
}

/**
 * This wrapper is used to throw on error, handle fetch errors, and retry on
 * @param method openapi-typescript client method
 * @returns wrapped method with custom error handling
 */
export function wrapClientMethod<
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type -- types are same as the library ones
    Paths extends Record<string, Record<HttpMethod, {}>>,
    Method extends HttpMethod,
    Media extends MediaType = MediaType,
>(method: ClientMethod<Paths, Method, Media>) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters -- types are same as the library ones
    return async <Path extends PathsWithMethod<Paths, Method>, Init extends MaybeOptionalInit<Paths[Path], Method>>(
        url: Path,
        ...init: HasRequiredKeys<Init> extends never
            ? [(Init & Record<string, unknown>)?] // note: the arbitrary [key: string]: addition MUST happen here after all the inference happens (otherwise TS can’t infer if it’s required or not)
            : [Init & Record<string, unknown>]
    ) =>
        // @ts-expect-error -- unknown library type error
        method(url, ...init)
            .then(handleResult)
            .catch((e: unknown) => {
                if (ApiError.is(e) && e.cause === ApiError.Causes.Unauthorized) {
                    return (
                        authState
                            .getValue()
                            .updateToken()
                            // @ts-expect-error -- unknown library type error
                            .then(() => method(url, ...init))
                            .then(handleResult)
                    );
                }

                throw e;
            })
            .catch((e: unknown) => {
                if (e instanceof TypeError) {
                    throw new RequestError({ cause: e });
                }

                throw e;
            });
}
