import { GLOBAL_WINDOW } from '../../const/GLOBAL_WINDOW';

const LOCAL_STORAGE_ERROR = 'localStorage is not supported on this device';

/**
 * localStorage can be unavailable on mobile devices.
 * That's why do not use localStorage, use this storage instead.
 */
export const persistentStorage = {
    getItem(key: string): string | null {
        try {
            return GLOBAL_WINDOW.localStorage.getItem(key);
        } catch {
            return null;
        }
    },

    setItem(key: string, value: string) {
        try {
            GLOBAL_WINDOW.localStorage.setItem(key, value);
        } catch {
            // eslint-disable-next-line no-console -- We do not throw error, it's an expected behavior.
            console.warn(LOCAL_STORAGE_ERROR);
        }
    },

    removeItem(key: string) {
        try {
            GLOBAL_WINDOW.localStorage.removeItem(key);
        } catch {
            // eslint-disable-next-line no-console -- We do not throw error, it's an expected behavior.
            console.warn(LOCAL_STORAGE_ERROR);
        }
    },
};
