import { META_ENVIRONMENT_KEY } from '@space/common/src/const/META_ENVIRONMENT_KEY';
import type { FrontEnvConfig } from '@space/common/src/const/FRONT_ENV_SCHEMA';
import { FRONT_ENV_SCHEMA } from '@space/common/src/const/FRONT_ENV_SCHEMA';

// eslint-disable-next-line no-restricted-globals -- it's okay to use document to parse client-side environment config from meta headers
const metaHeader = document.querySelector<HTMLMetaElement>(`meta[name="${META_ENVIRONMENT_KEY}"]`);

if (metaHeader == null) {
    throw new Error('Invalid environment. [metaHeader] is empty.');
}

/**
 * This data is being placed in html <meta> header in order to pass ome parameters from BFF to client side
 */
export const FRONTEND_ENV: FrontEnvConfig = FRONT_ENV_SCHEMA.cast(JSON.parse(metaHeader.content));
