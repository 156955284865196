import { ApiError } from '@space/common/src/entities/ApiError/ApiError';

import { request } from '../request/request';
import { FRONTEND_ENV } from '../../const/FRONTEND_ENV';
import { authState } from '../authState/authState';
import type { RequestOptions } from '../request/types/RequestOptions';
import type { Request } from '../request/request';
import type { RequestParameters } from '../request/types/RequestParameters';

export const backendApiRequest: Request = async (path, requestParameters = {}) => {
    try {
        return await request(getRequestOptions(path, requestParameters));
    } catch (error) {
        // The refresh logic is handled by a Keycloak service,
        // but if a token is still expired, try to refresh it.
        if (ApiError.is(error) && error.cause === ApiError.Causes.Unauthorized) {
            try {
                // It throws a specific error, catch it and return an API error.
                await authState.getValue().updateToken();

                // Fetch again with the refreshed access token.
                return await request(getRequestOptions(path, requestParameters));
            } catch {
                throw error;
            }
        }

        throw error;
    }
};

const getRequestOptions = (path: string, requestParameters: RequestParameters): RequestOptions => {
    let options: RequestOptions = {
        url: new URL(path, FRONTEND_ENV.BACKEND_API_URL).toString(),
        requestParameters,
    };

    const currentAuthState = authState.getValue();

    if (currentAuthState.status === 'authenticated') {
        options = {
            ...options,
            accessToken: currentAuthState.accessToken,
        };
    }

    return options;
};
