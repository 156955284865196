import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resourcesList } from '../../i18n/resourcesList';

export function initI18n() {
    const i18n = createInstance();

    void i18n.use(initReactI18next).init({
        // TODO: use language detector
        lng: 'en',
        defaultNS: 'translation',
        resources: resourcesList,
        interpolation: {
            // React already safes from XSS.
            // See https://www.i18next.com/translation-function/interpolation#unescape
            escapeValue: false,
        },
    });

    return i18n;
}
