import { Signal } from '@space/common/src/utils/Signal/Signal';
import type { KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { GLOBAL_WINDOW } from '@space/common/src/const/GLOBAL_WINDOW';
import { assertIsNonNullable } from '@space/common/src/utils/assertions/assertIsNonNullable';
import { persistentStorage } from '@space/common/src/utils/persistentStorage/persistentStorage';
import type { Seconds } from '@space/common/src/types/Seconds';

import { FRONTEND_ENV } from '../../const/FRONTEND_ENV';
import { PUBLIC_URL } from '../../const/PUBLIC_ROUTE';

type LoadingState = {
    readonly status: 'loading';
};

type AuthenticatedState = {
    readonly status: 'authenticated';
    readonly accessToken: string;
};

type UnauthenticatedState = {
    readonly status: 'unauthenticated';
};

export type KeycloakState = AuthenticatedState | LoadingState | UnauthenticatedState;

const INITIAL_STATE: KeycloakState = {
    status: 'loading',
};

/**
 * The base64 encoded token that can be sent in the Authorization header in requests to services.
 */
const ACCESS_TOKEN_STORAGE_KEY = 'KEYCLOAK_ACCESS_TOKEN';

/**
 * The base64 encoded refresh token that can be used to retrieve a new token.
 */
const REFRESH_TOKEN_STORAGE_KEY = 'KEYCLOAK_REFRESH_TOKEN';

/**
 * The base64 encoded ID token.
 * This token is needed to bypass the logout confirmation page.
 * @see https://github.com/keycloak/keycloak/discussions/12114#discussioncomment-6927266
 */
const ID_TOKEN_STORAGE_KEY = 'KEYCLOAK_ID_TOKEN';

/**
 * The estimated time difference between the browser time and the Keycloak server in seconds.
 * This value is just an estimation, but is accurate enough when determining if a token is expired or not.
 *
 * We set it to zero, since in this case the token will be refreshed immediately.
 * If we do not set it to zero and our calculations are wrong, we can continue using the expired token.
 * If you face with any issues with a refresh token, try to adjust this value.
 * @see https://github.com/keycloak/keycloak/blob/main/js/libs/keycloak-js/src/keycloak.js#L595
 */
const TIME_SKEW: Seconds = 0;

/**
 * This service abstracts the `keycloak-js` adapter.
 * The adapter handles most of the cases out of the box, we only need to configure it.
 * Please note that keycloak-js can handle the token management out of the box,
 * but we do it on our side to avoid the authentication flow (redirects) on every page reload.
 * @see https://github.com/keycloak/keycloak/tree/main/js/libs/keycloak-js
 */
export class KeycloakService {
    private readonly keycloak = new Keycloak({
        url: FRONTEND_ENV.KEYCLOAK_URL,
        realm: FRONTEND_ENV.KEYCLOAK_REALM,
        clientId: FRONTEND_ENV.KEYCLOAK_CLIENT_ID,
    });

    public readonly stateSignal = new Signal<KeycloakState>(INITIAL_STATE);

    /**
     * Inits the Keycloak adapter.
     * @returns true If the user has become authenticated, otherwise false.
     */
    public async init(): Promise<boolean> {
        // It must be called before keycloak.init, otherwise refresh doesn't work.
        // The arrow function is used to preserve this context.
        this.keycloak.onTokenExpired = () => {
            void this.updateToken();
        };

        try {
            const initOptions: KeycloakInitOptions = {
                timeSkew: TIME_SKEW,
            };

            const { accessToken, refreshToken, idToken } = this.getTokens();

            // If they do not exist, it's not critical.
            // Keycloak will start the authentication flow.
            if (accessToken && refreshToken && idToken) {
                initOptions.token = accessToken;
                initOptions.idToken = idToken;
                initOptions.refreshToken = refreshToken;
            }

            const authenticated = await this.keycloak.init(initOptions);

            const { pathname } = GLOBAL_WINDOW.location;

            // If the token is expired, authenticated is also false.
            if (!authenticated) {
                this.stateSignal.next({
                    status: 'unauthenticated',
                });

                // If not authenticated, and the URL doesn't start PUBLIC_URL, login the user.
                if (!pathname.startsWith(PUBLIC_URL)) {
                    this.login();
                }

                return false;
            }

            this.onTokensChange();
        } catch (error: unknown) {
            this.login();

            // Throw the error so Sentry catches it.
            throw error;
        }

        return true;
    }

    /**
     * Redirects to the login form.
     * It must be the arrow function to preserve this context.
     */
    public readonly login = (options?: KeycloakLoginOptions) => {
        // There can be situations where the tokens are invalid or expired.
        // Init returns false in such cases, triggering a redirect to a login page.
        // Being on a login page means that the user doesn't have valid tokens.
        // That's why tokens must be removed, since users could be redirected to login page
        // even when the tokens are still present (but invalid).
        // Even though keycloak-js can handle old invalid tokens,
        // it is recommended to explicitly remove them.
        this.removeTokens();

        void this.keycloak.login(options);
    };

    /**
     * Redirects to the logout form.
     * It must be the arrow function to preserve this context.
     */
    public readonly logout = (options?: KeycloakLogoutOptions) => {
        // Tokens should be deleted on logout because when a user logs out,
        // there should be no tokens associated with their session.
        // Even though keycloak-js can handle old invalid tokens,
        // it is recommended to explicitly remove them.
        this.removeTokens();

        void this.keycloak.logout(options);
    };

    /**
     * Updates the Keycloak token.
     * Handles multiple subsequent requests.
     * It must be the arrow function to preserve this context.
     * @returns true if the token was successfully refreshed, or false if the token is still valid.
     * @throws {Error} Failed to refresh the token, or the session has expired.
     */
    public readonly updateToken = async (): Promise<boolean> => {
        // If the token expires within minValidity seconds, the token is refreshed.
        // If -1 is passed as the minValidity, the token will be forcibly refreshed.
        const refreshed = await this.keycloak.updateToken(-1);

        if (refreshed) {
            this.onTokensChange();
        }

        return refreshed;
    };

    /**
     * Validates the Keycloak tokens, saves them to the storage
     * and emits an access token using Signal.
     */
    private onTokensChange() {
        const { token, refreshToken, idToken } = this.keycloak;

        assertIsNonNullable(token, 'token');
        assertIsNonNullable(refreshToken, 'refreshToken');
        assertIsNonNullable(idToken, 'idToken');

        this.setTokens(token, refreshToken, idToken);

        this.stateSignal.next({
            status: 'authenticated',
            accessToken: token,
        });
    }

    private getTokens() {
        return {
            accessToken: persistentStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
            refreshToken: persistentStorage.getItem(REFRESH_TOKEN_STORAGE_KEY),
            idToken: persistentStorage.getItem(ID_TOKEN_STORAGE_KEY),
        };
    }

    private setTokens(accessToken: string, refreshToken: string, idToken: string) {
        persistentStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
        persistentStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
        persistentStorage.setItem(ID_TOKEN_STORAGE_KEY, idToken);
    }

    private removeTokens() {
        persistentStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        persistentStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        persistentStorage.removeItem(ID_TOKEN_STORAGE_KEY);
    }
}
