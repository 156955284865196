import type { InferType } from 'yup';
import { mixed, number, object, string } from 'yup';

import type { FeatureFlags } from '../types/FeatureFlags';

export const FRONT_ENV_SCHEMA = object({
    DEPLOYMENT_ENV: string().oneOf(['prod', 'dev', 'local', 'test']).required(),
    BACKEND_API_URL: string().required(),
    KEYCLOAK_URL: string().required(),
    KEYCLOAK_REALM: string().required(),
    KEYCLOAK_CLIENT_ID: string().required(),
    SENTRY_DSN: string(),
    USER_SESSION_ID: string(),
    APP_URL: string().required(),
    COUNTER_ID: number(),
    FEATURE_FLAGS: mixed<FeatureFlags>().required(),
    BANNER_PREVIEW_IFRAME_URL: string().required(),
});

export type FrontEnvConfig = Readonly<InferType<typeof FRONT_ENV_SCHEMA>>;
